import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

import profileIcon from '../assets/images/users/profile.png';
import { kickUser } from '../helpers';

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  logout = async () => {
    await kickUser();
  };

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={profileIcon}
              alt="Header Avatar"
            />
          </DropdownToggle>
          <DropdownMenu right>
            <Link to="/profile" className="dropdown-item">
              <i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>
              Profile
            </Link>
            <div className="dropdown-divider" />
            <div
              className="dropdown-item"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.logout();
              }}
            >
              <i className="mdi mdi-logout-variant font-size-17 align-middle mr-1"></i>
              <span>Logout</span>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withRouter(ProfileMenu);
