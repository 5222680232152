import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { userPump } from 'store/pump/actions';
import Layout from 'components/VerticalLayout';
import DashBoardPumpTile from 'pages/Dashboard/DashboardPump';
import { debounce, setCookie } from 'helpers';

import 'chartist/dist/scss/chartist.scss';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      pumpList: [],
    };
  }

  initialRequestParams = () => {
    return {
      search: '',
    };
  };

  componentDidUpdate() {
    const { data } = this.props;
    if (data.length > 0 && data[0].length > 0) {
      localStorage.setItem('pump_id', data[0][0].id);
      localStorage.setItem('pump_name', data[0][0].name);
      localStorage.setItem('device_id', data[0][0].device_id);
    }
  }

  componentDidMount() {
    this.props.data.length === 0 && this.props.userPump();
    this.props.userPump();
  }

  onClick = (id, device_id, name) => {
    localStorage.setItem('pump_id', id);
    localStorage.setItem('pump_name', name);
    localStorage.setItem('device_id', device_id);
    this.props.history.push(`/pump/${device_id}/details`);
  };

  debounceSearch = debounce(() => {
    this.props.userPump(this.requestParams.search);
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.setState(this.state);
    this.setState(this.state);
    this.debounceSearch();
  };

  render() {
    const { onClick, onChange } = this;

    const TopBarProps = {
      dateFilter: false,
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };

    const props = {
      onClick,
      data: this.props.data,
    };
    return (
      <React.Fragment>
        <Layout {...TopBarProps}>
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Dashboard</h4>
                </div>
              </Col>
            </Row>
            <Row style={{ marginLeft: '1%' }}>
              <DashBoardPumpTile {...props} />
            </Row>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapDispatchtoProps = (state) => {
  const { error, loading, data } = state.Pump;
  return { error, loading, data };
};

export default withRouter(connect(mapDispatchtoProps, { userPump })(Dashboard));
