import React, {Component} from "react";
import {Row, Col, Card, CardBody, Alert} from "reactstrap";

// Redux
import {withRouter, Link} from "react-router-dom";

// availity-reactstrap-validation
import {AvForm, AvField} from "availity-reactstrap-validation";

// action
import {updatePassword} from "api/login";
import {setCookie} from "helpers";

// import images
import {success, handleResponse} from "components/handleResponse";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        otp_code: "",
        password: "",
      },
    };
  }

  formSubmit = () => {
    const {otp_code, password} = this.state.data;
    let user_name = this.props.location.state;
    let requestParams = {
      otp_code: otp_code,
      password: password,
      user_name: user_name,
    };
    updatePassword({...requestParams})
      .then(({data}) => {
        setCookie("token", data.auth_token);
        setCookie("user_id", data.id);
        success("Password Updated Successfully..!!!");
        window.location.pathname = "/dashboard";
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleValueChange = (value) => {
    const data = {...this.state.data};
    data[value.name] = value.value;
    this.setState({data});
  };

  render() {
    const {otp_code, password} = this.state.data;
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  <Card className="overflow-hidden">
                    <div className="bg-primary">
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">
                          Forget Password
                        </h5>
                        <p className="text-white-50">
                          Enter verification code.
                        </p>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        {this.props.forgetError && this.props.forgetError ? (
                          <Alert color="danger" style={{marginTop: "13px"}}>
                            {this.props.forgetError}
                          </Alert>
                        ) : null}
                        {this.props.forgetSuccess ? (
                          <Alert color="success" style={{marginTop: "13px"}}>
                            {this.props.forgetSuccess}
                          </Alert>
                        ) : null}

                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={(e) => this.formSubmit(e)}
                        >
                          <div className="form-group">
                            <AvField
                              name="otp_code"
                              label="Enter Verification Code"
                              className="form-control"
                              placeholder="Enter Verification Code"
                              type="text"
                              value={otp_code}
                              required
                              onChange={(e) => {
                                this.handleValueChange(e.target);
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="password"
                              label="Password"
                              type="password"
                              required
                              value={password}
                              placeholder="Enter New Password"
                              onChange={(e) => {
                                this.handleValueChange(e.target);
                              }}
                            />
                          </div>
                          <Row className="form-group">
                            <Col className="text-right">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Update
                              </button>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>
                      Go back to{" "}
                      <Link to="/" className="font-weight-medium text-primary">
                        Login
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ForgetPasswordPage);
