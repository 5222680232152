import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Loader from '../../components/Loader';
import { signIn } from 'api/login';
import { setCookie } from 'helpers';
import { handleResponse, error } from 'components/handleResponse';
import { AppVersion } from 'config';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleValidSubmit = async () => {
    const { user_name, password } = this.state;
    await signIn(user_name, password)
      .then(
        ({
          data: {
            id,
            first_name,
            last_name,
            email,
            auth_token,
            roles,
            role_group,
          },
        }) => {
          this.setState({ click: false });
          let role = roles && roles.map((role) => role.name);
          id && setCookie('user_id', id, 15);
          first_name && setCookie('first_name', first_name, 15);
          last_name && setCookie('last_name', last_name, 15);
          email && setCookie('email', email, 15);
          setCookie('adminAccess', role_group.name, 15);
          roles && window.localStorage.setItem('roles', JSON.stringify(role));
          setCookie('token', auth_token, 15);
          setCookie('active', '1', 0, 2);
          window.location.reload();
        }
      )
      .catch((err) => {
        this.setState({ click: false });
        if (err[0] === 401) {
          error(err[1].message);
        } else {
          handleResponse(err[0], err[1]);
        }
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  {this.props.loading ? <Loader /> : null}

                  <Card className="overflow-hidden">
                    <div className="bg-primary">
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">
                          Welcome Back !
                        </h5>
                        <p className="text-white-50">Sign in to continue</p>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          <div className="form-group">
                            <AvField
                              name="user_name"
                              id="user_name"
                              label="Email or Phone"
                              className="form-control"
                              value=""
                              placeholder="Enter email or phone"
                              type="text"
                              required
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="password"
                              id="password"
                              label="Password"
                              type="password"
                              required
                              value=""
                              placeholder="Enter Password"
                              onChange={this.handleChange}
                            />
                          </div>

                          <Row className="form-group">
                            <Col sm={6}>
                              &nbsp;
                              {/* <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <label className="custom-control-label" for="customControlInline">Remember me</label>
                                                        </div> */}
                            </Col>
                            <Col sm={6} className="text-right">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </button>
                            </Col>
                          </Row>
                          {/* <Row className="form-group mt-2 mb-0">
                            <div className="col-12 mt-4">
                              <Link to="/forgot-password">
                                <i className="mdi mdi-lock"></i> Forgot your
                                password?
                              </Link>
                            </div>
                          </Row> */}
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="mt-5 text-center">
                  <p className="mb-0" style={{ color: '#636e72' }}>
                    v{`${AppVersion}`}
                  </p>
                </div>
                <div className="mt-5 text-center">
                  © {new Date().getFullYear()} Si'Tarc{' '}
                  <i className="mdi mdi-heart text-danger"></i> Katomaran
                  Technology
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
