import React, {Component} from "react";
import {Row, Col, Card, CardBody, FormGroup, Button} from "reactstrap";
import {AvForm, AvField} from "availity-reactstrap-validation";
import {updateProfile, updatePassword} from "../../../api/users";
import {success, handleResponse} from "../../../components/handleResponse";
import {setCookie, getCookie} from "../../../helpers";

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.id = null;
    this.state = {
      data: {
        first_name: getCookie("first_name") || "",
        last_name: getCookie("last_name") || "",
        email: getCookie("email") || "",
        phone: "",
      },
      password_data: {
        current_password: "",
        new_password: "",
      },
    };
  }

  handleChange = (e, pass) => {
    let {data, password_data} = this.state;
    let {name, value} = e.target;
    if (pass) {
      password_data[name] = value;
      this.setState({password_data});
    }
    data[name] = value;
    this.setState({data});
  };

  onSubmit = async (e, pass) => {
    const {data, password_data} = this.state;
    let requestValue = {
      ...data,
    };

    let pass_data = {
      ...password_data,
    };

    let params = pass ? pass_data : requestValue;

    let action = pass ? updatePassword : updateProfile;
    await action(params)
      .then(({data: {first_name, last_name, email, message, auth_token}}) => {
        success("Updated Successfully");
        first_name && setCookie("first_name", first_name);
        last_name && setCookie("last_name", last_name);
        email && setCookie("email", email);
        auth_token && setCookie("token", auth_token);
        this.props.data.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    if (this.props.data) {
      const {first_name, last_name, phone, email} = this.props.data;

      this.setState({
        data: {
          ...this.state.data,
          first_name,
          last_name,
          phone,
          email,
        },
      });
    }
  };

  render() {
    const {onCancel} = this.props.data;
    const {handleChange} = this;
    const {first_name, last_name, email, phone} = this.state.data;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Update Profile</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <AvField
                      name="first_name"
                      label="First Name  "
                      placeholder={"First Name"}
                      value={first_name}
                      onChange={handleChange}
                      type="text"
                      errorMessage="Enter First Name"
                      validate={{required: {value: true}}}
                    />
                    <AvField
                      name="last_name"
                      label="Last Name  "
                      placeholder={"Last Name"}
                      value={last_name}
                      onChange={handleChange}
                      type="text"
                      errorMessage="Enter Last Name"
                    />
                    <AvField
                      name="email"
                      label="E-Mail  "
                      placeholder={"Email"}
                      value={email}
                      onChange={handleChange}
                      type="email"
                      errorMessage="Invalid Email"
                      validate={{
                        required: {value: true},
                        email: {value: true},
                      }}
                    />

                    <AvField
                      name="phone"
                      label="Phone"
                      placeholder={"Phone"}
                      value={phone}
                      type="phone"
                      onChange={handleChange}
                      errorMessage="Invalid Phone"
                      validate={{
                        required: {value: true},
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Only Numbers",
                        },
                      }}
                    />

                    <FormGroup>
                      <Button
                        type="submit"
                        color="primary"
                        className="mr-1"
                        onClick={this.onSubmit}
                      >
                        Submit
                      </Button>{" "}
                      <Button type="reset" color="secondary" onClick={onCancel}>
                        Cancel
                      </Button>
                    </FormGroup>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <AvForm>
                    <AvField
                      name="current_password"
                      label="Current Password"
                      placeholder={"Current Password"}
                      onChange={(e) => {
                        handleChange(e, "pass");
                      }}
                      type="password"
                      errorMessage="Enter Valid Password"
                      validate={{required: {value: true}}}
                    />
                    <AvField
                      name="new_password"
                      label="New Password"
                      placeholder={"New Password"}
                      onChange={(e) => {
                        handleChange(e, "pass");
                      }}
                      type="password"
                      errorMessage="Password not matched"
                    />

                    <FormGroup>
                      <Button
                        type="submit"
                        color="primary"
                        className="mr-1"
                        onClick={(e) => {
                          this.onSubmit(e, "pass");
                        }}
                      >
                        Submit
                      </Button>{" "}
                      <Button type="reset" color="secondary" onClick={onCancel}>
                        Cancel
                      </Button>
                    </FormGroup>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default ProfileForm;
