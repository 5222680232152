import { USER_PUMP, API_ERROR } from './actionTypes';

export const userPump = (search) => {
  return {
    type: USER_PUMP,
    payload: { search },
  };
};

// export const pumpShow = (id) => {
//   return {
//     type: USER_PUMP,
//     payload: { id },
//   };
// };

export const userPumpSuccess = (data) => {
  return {
    type: 'USER_PUMP_SUCESS',
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
