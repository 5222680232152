import React from 'react';

import {
  Users,
  DashBoard,
  PumpDetails,
  Pumps,
  Profile,
  ErrorLogs,
} from 'pages/Views';
import { fetchRoles } from 'api/users';
import { handleResponse } from 'components/handleResponse';

export const menus = [
  {
    index: 1,
    name: 'Dashboard',
    icon: 'ti-dashboard',
    url: () => `/dashboard`,
    match_url: '/dashboard',
    render: (props) => <DashBoard {...props} />,
    show: true,
    permissions: {
      'api/v1/pumps': ['list'],
    },
  },
  {
    index: 2,
    name: 'Pump Details',
    icon: 'ti-server',
    url: (id) => (!id ? `/dashboard` : `/pump/${id}/details`),
    match_url: '/pump/:id/details',
    render: (props) => <PumpDetails {...props} />,
    show: true,
    permissions: {
      'api/v1/pumps': ['summary', 'live'],
    },
  },
  {
    index: 3,
    name: 'Error Logs',
    icon: 'ti-alert',
    url: (id) => (!id ? `/dashboard` : `/pump/${id}/logs`),
    match_url: '/pump/:id/logs',
    render: (props) => <ErrorLogs {...props} />,
    show: true,
    permissions: {
      'api/v1/pumps': ['logs'],
    },
  },
  {
    index: 4,
    name: 'Pumps',
    icon: 'ti-rocket',
    url: () => `/pumps`,
    match_url: '/pumps',
    render: (props) => <Pumps {...props} />,
    show: true,
    permissions: {
      'api/v1/pumps': ['list', 'create', 'update'],
      'api/v1/users': ['index'],
    },
  },
  {
    index: 5,
    name: 'Users',
    icon: 'ti-user',
    url: () => `/users`,
    match_url: '/users',
    render: (props) => <Users {...props} />,
    show: true,
    permissions: {
      'api/v1/users': ['index', 'create', 'update'],
      'api/v1/pumps': ['index'],
    },
  },
  {
    index: 6,
    name: 'Profile',
    icon: 'ti-user',
    url: () => `/profile`,
    match_url: '/profile',
    render: (props) => <Profile {...props} />,
    show: false,
    permissions: {
      'api/v1/users': ['profile'],
    },
  },
];

const Promise1 = new Promise((res) => {
  try {
    const data = JSON.parse(window.localStorage.getItem('user_roles'));
    if (!data || 'object' !== typeof data) {
      res([false]);
    }
    res([true, data]);
  } catch (err) {
    res([false]);
  }
});

export const filteredMenu = async () => {
  let usr_roles = JSON.parse(window.localStorage.getItem('user_roles'));
  let [status, userRoles] = await Promise1;
  if (!status) {
    await fetchRoles()
      .then(({ data }) => {
        userRoles = data;
        status = true;
        window.localStorage.setItem('user_roles', JSON.stringify(userRoles));
      })
      .catch((error) => {
        handleResponse(error);
      });
  }
  if (status && usr_roles) {
    return menus.filter((menu) =>
      Object.keys(menu.permissions).every(
        (permission) =>
          Object.keys(userRoles).includes(permission) &&
          menu.permissions[permission].every((sub_permission) =>
            userRoles[permission].includes(sub_permission)
          )
      )
    );
  } else {
    return menus;
  }
};
