import React, { Component } from 'react';
import { DatetimeRangePickerTrigger } from 'rc-datetime-picker';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import 'rc-datetime-picker/dist/picker.css';
import { rounfOffTime } from 'helpers';
import moment from 'moment';
const styles = () => ({
  input: {
    padding: 10,
    color: 'rgb(108, 92, 231)',
    fontSize: 15,
    textAlign: 'right',
    width: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
  },
  picker: {
    marginTop: 10,
    position: 'relative',
    // borderTop: '1px solid lightgray',
    // borderLeft: '1px solid lightgray',
    paddingRight: 10,
    paddingLeft: 10,
  },
  labelIcon: {
    position: 'absolute',
    top: 10,
    left: 10,
    color: 'gray',
  },
  labelText: {
    position: 'absolute',
    top: -20,
  },
});

class DateTimePicker extends Component {
  handleChange = (moment) => {
    this.props.onChange(moment.start, moment.end);
  };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.start.format('YYYY-MM-DD hh:mm') !==
        nextProps.start.format('YYYY-MM-DD hh:mm') ||
      this.props.end.format('YYYY-MM-DD hh:mm') !==
        nextProps.end.format('YYYY-MM-DD hh:mm')
    );
  }

  render() {
    const { classes, start, end } = this.props;
    const momentTime = {
      start: start,
      end: end,
    };

    return (
      <form
        className={classes.container}
        onSubmit={(e) => e.preventDefault()}
        noValidate
      >
        <DatetimeRangePickerTrigger
          showCalendarPicker
          showTimePicker
          moment={momentTime}
          onChange={this.handleChange}
          confirmButtonText="Search"
          maxDate={moment()}
          styles={{ marginRight: '300px' }}
        >
          <div
            style={{
              display: 'flex',
              marginTop: 12,
              color: '#000',
              width: 370,
            }}
          >
            <div
              className={classes.picker}
              style={{ border: '1px solid lightgray', borderRight: 'none' }}
            >
              <label className={classes.labelText}>From</label>
              <span className={classes.labelIcon}>
                <i className="mdi mdi-calendar"></i>
              </span>
              <input
                type="text"
                value={rounfOffTime(momentTime.start).format('DD-MMM-YY HH:mm')}
                className={classes.input}
                readOnly
              />
            </div>

            <div
              className={classes.picker}
              style={{ border: '1px solid lightgray' }}
            >
              <label className={classes.labelText}>To</label>
              <span className={classes.labelIcon}>
                <i className="mdi mdi-calendar"></i>
              </span>
              <input
                type="text"
                // value={momentTime.end.format('DD-MMM-YY HH:mm')}
                value={rounfOffTime(momentTime.end).format('DD-MMM-YY HH:mm')}
                className={classes.input}
                readOnly
              />
            </div>
          </div>
        </DatetimeRangePickerTrigger>
      </form>
    );
  }
}

DateTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateTimePicker);
