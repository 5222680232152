import {apiHost} from "../config";
import {headers} from "../helpers";

export default function ({uri, method = "GET", body, header}) {
  const reqHeaders = header || headers();

  if (!reqHeaders.Authorization) {
    return;
  }

  return fetch(apiHost + uri, {
    method,
    body,
    headers: reqHeaders,
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    throw [response.status, await response.json()];
  });
}
