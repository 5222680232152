import { takeEvery, fork, put, all } from 'redux-saga/effects';

// Login Redux States
import { USER_PUMP } from './actionTypes';
import { userPumpSuccess, apiError } from './actions';

import {
  // fetchPumps,
  fetchUserPump,
  // getPumpData,
  // fetchSummary,
  // createPump,
  // updateStatus,
  // getTileDetail,
} from 'api/pumps';

function* UserPump({ payload: { search } }) {
  try {
    const response = yield fetchUserPump(search || '');
    if (response.data.length > 0) {
      localStorage.setItem('pump_id', localStorage.getItem('pump_id') || response.data[0][0].id);
      localStorage.setItem('pump_name', localStorage.getItem('pump_name') || response.data[0][0].name);
      localStorage.setItem('device_id', localStorage.getItem('device_id') || response.data[0][0].device_id);
    }
    yield put(userPumpSuccess(response.data));
  } catch (error) {
    yield put(apiError(error));
    console.log(error);
  }
}

// function* PumpShow({ payload: { id } }) {
//   try {
//     const response = yield getPumpData(id || '');
//     if (response.data) {
//       setCookie('pump_id', response.data.id);
//       setCookie('pump_name', response.data.name);
//       setCookie('device_id', response.data.device_id);
//     }
//     yield put(userPumpSuccess(response.data));
//   } catch (error) {
//     yield put(apiError(error));
//     console.log(error);
//   }
// }

export function* watchUserPump() {
  yield takeEvery(USER_PUMP, UserPump);
}

// export function* watchPumpShow() {
//   yield takeEvery(USER_PUMP, PumpShow);
// }

function* pumpSaga() {
  yield all([fork(watchUserPump)]);
}

export default pumpSaga;
