import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/scss/theme.scss';
import 'app.css';
import CustomRoutes from 'routes/CustomRoutes';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Router>
          <CustomRoutes />
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
