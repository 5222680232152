import React, { Component } from 'react';
import moment from 'moment';
import { Card, CardBody } from 'reactstrap';
import servicesIcon1 from '../assets/images/services-icon/01.png';
import { timeDiff, presence, capitalizeFirstLetter } from '../helpers';

class UserTile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { data, userAdd } = this.props;
    return (
      <React.Fragment>
        {data &&
          data.map((user, i) => (
            <Card
              className="mini-stat bg-primary text-white"
              onClick={() => {
                userAdd(user);
              }}
              style={{ cursor: 'pointer', width: '325px', marginRight: '2%' }}
              key={i}
            >
              <CardBody>
                <div className="mb-4">
                  <div className="float-left mini-stat-img mr-4">
                    <img src={servicesIcon1} alt="" />
                  </div>
                  <h5 className="font-size-12 text-uppercase mt-0 text-white-50">
                    {user.role_group.name}
                  </h5>
                  <h4
                    className="font-weight-medium font-size-20"
                    style={{ color: '#fff' }}
                  >
                    {presence(user.first_name)}
                  </h4>
                  <div
                    className={
                      user.status === 'active'
                        ? 'mini-stat-label bg-success'
                        : 'mini-stat-label bg-danger'
                    }
                  >
                    <p className="mb-0">{capitalizeFirstLetter(user.status)}</p>
                  </div>
                </div>
                <div className="pt-2">
                  <div className="float-right">
                    <p className="text-white-50 mb-0 mt-1">
                      {presence(user.email)}
                    </p>
                    <p className="text-white-50 mb-0 mt-1">
                      {presence(user.phone)}
                    </p>
                  </div>
                  <div className="text-white-50 mb-0 mt-1">
                    Last Login
                    <p>{timeDiff(moment(), user.last_login)}</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}
      </React.Fragment>
    );
  }
}
export default UserTile;
