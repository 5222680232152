import React, {Component} from "react";
import {Row, Col, Card, CardBody} from "reactstrap";

// Redux
import {Link} from "react-router-dom";

// availity-reactstrap-validation
import {AvForm, AvField} from "availity-reactstrap-validation";
import {forgotPassword} from "api/login";
import {handleResponse} from "components/handleResponse";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        user_name: "",
      },
    };
  }

  handleValidSubmit = async () => {
    const {user_name} = this.state.data;
    await forgotPassword(user_name)
      .then(({message}) => {
        this.props.history.push({
          pathname: `/reset-password`,
          state: user_name,
        });
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  handleChange = (e) => {
    let {data} = this.state;
    let {name, value} = e.target;
    data[name] = value;
    this.setState({data});
  };

  render() {
    const {handleValidSubmit, handleChange} = this;
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  <Card className="overflow-hidden">
                    <div className="bg-primary">
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">
                          Forget Password
                        </h5>
                        <p className="text-white-50">
                          Enter your email/phone below and we would send a otp
                          to reset your password.
                        </p>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={handleValidSubmit}
                        >
                          <div className="form-group">
                            <AvField
                              name="user_name"
                              label="Email/Phone"
                              className="form-control"
                              placeholder="Enter email/phone"
                              type="text"
                              onChange={handleChange}
                            />
                          </div>
                          <Row className="form-group">
                            <Col className="text-right">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Reset
                              </button>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>
                      Go back to{" "}
                      <Link to="/" className="font-weight-medium text-primary">
                        Login
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgetPasswordPage;
