import React, { Component } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  Modal,
} from 'reactstrap';
import logosmImg from '../../assets/images/Logo/Si.png';
import logolightImg from '../../assets/images/Logo/SiTarc.png';

import InputBase from '@material-ui/core/InputBase';
import { DateTimePicker, ProfileMenu } from 'components';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { userPump } from 'store/pump/actions';
import { handleResponse, success } from 'components/handleResponse';
import { updateStatus } from 'api/pumps';
import { rmSpecialCharacterCaptalize } from 'helpers';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchOpen: false,
      loader: false,
      btnprimary1: false,
      modal_center: false,
      silderValue: 100,
      modelOpen: false,
      enableControl: false,
      onDisable: false,
      offDisable: false
    };

    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.tog_center = this.tog_center.bind(this);
  }

  toggleSearch() {
    this.setState({ isSearchOpen: !this.state.isSearchOpen });
  }

  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  componentDidMount() {
    try {
      let usr_roles = JSON.parse(window.localStorage.getItem('user_roles'));
      if (usr_roles['api/v1/pumps'].includes('update_state')) {
        this.setState({ enableControl: true });
      }
    } catch (err) {
      console.log(err);
    }
  }

  dateChange = (from_time, to_time) => {
    this.setState({
      dateField: false,
      loader: true,
    });
    this.props.onDateChange(
      moment(from_time).format(),
      moment(to_time).format(),
      this.state.loader
    );
  };

  tog_center() {
    this.setState((prevState) => ({
      modal_center: !prevState.modal_center,
      modelOpen: true,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  onConfirm = (state) => {
    this.setState({ onDisable: true, offDisable: true })
    let pumpId = localStorage.getItem('pump_id');
    if (!pumpId) {
      this.props.userPump();
    }
    let requestValue = { rpm: this.state.silderValue, state };
    updateStatus(pumpId, requestValue)
      .then((data) => {
        success(data.message);
        this.setState({
          modelOpen: false,
          onDisable: false, offDisable: false
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  tableInputKeyPress = (e) => {
    e = e || window.event;
    var key = e.keyCode;
    if (key === 13) {
      return true;
    }
  };

  render() {
    const {
      search,
      onChange,
      searchOpen,
      fromTime,
      toTime,
      dateFilter,
      select,
      handleSelect,
      device,
      stateDetails,
      controlActivity,
    } = this.props;
    let pumpList = this.props.userPumpList.length > 0 && this.props.userPumpList[0].map((pump) => ({
      label: pump.name,
      value: pump.id,
      device_id: pump.device_id,
    }));
    let statusBtnColor = '';
    switch (this.props.state) {
      case 'on':
        statusBtnColor = 'btn-success';
        break;
      case 'lost':
        statusBtnColor = 'btn-warning';
        break;
      default:
        statusBtnColor = 'btn-danger';
        break;
    }
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logosmImg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logolightImg} alt="" height="25" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="mdi mdi-menu"></i>
              </button>
              {select && (
                <div className="d-none d-sm-block">
                  <Dropdown
                    isOpen={this.state.createMenu}
                    toggle={() =>
                      this.setState({ createMenu: !this.state.createMenu })
                    }
                    className="pt-3 d-inline-block"
                  >
                    <DropdownToggle className="btn btn-secondary" tag="button">
                      {device?.label}
                      <i
                        className="mdi mdi-chevron-down"
                        style={{ marginLeft: '5px' }}
                      ></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      {(pumpList || []).map((obj, i) => {
                        return (
                          <DropdownItem
                            value="action"
                            onClick={() => handleSelect(obj)}
                            key={i}
                          >
                            {obj.label}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
              {stateDetails && this.props.state && (
                <div
                  style={{
                    marginTop: '3%',
                  }}
                >
                  <span
                    className={
                      'btn btn-rounded waves-effect waves-light m-t-5 ' +
                      statusBtnColor
                    }
                    style={{
                      marginLeft: 10,
                      marginTop: 10,
                      padding: '0px 10px',
                    }}
                  >
                    {rmSpecialCharacterCaptalize(this.props.state)}
                  </span>
                </div>
              )}
            </div>

            {this.state.enableControl && controlActivity && (
              <div>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={this.tog_center}
                  data-toggle="modal"
                  data-target=".bs-example-modal-center"
                >
                  <i
                    className="mdi mdi-settings"
                    style={{ marginRight: '6px', fontSize: 18 }}
                  ></i>
                  Control
                </button>

                {this.state.modelOpen && (
                  <Modal
                    isOpen={this.state.modal_center}
                    toggle={this.tog_center}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Controller</h5>
                      <button
                        type="button"
                        onClick={() => this.setState({ modal_center: false })}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <span>Speed</span>
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '85%' }}>
                          <input
                            // type="range"
                            min="0"
                            max="100"
                            value={this.state.silderValue}
                            onChange={(e) =>
                              this.setState({ silderValue: e.target.value })
                            }
                            style={{ width: '100%' }}
                          />
                        </div>
                        {/* <div style={{ padding: '0px 15px' }}>
                          <input
                            min="1"
                            max="100"
                            value={this.state.silderValue}
                            style={{
                              width: '50px',
                              border: 'none',
                              outline: 'none',
                              borderBottom: '1px solid',
                            }}
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        style={{ padding: '5px 30px' }}
                        onClick={() => this.setState({ modelOpen: false })}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-success"
                        onClick={() => this.onConfirm('on')}
                        style={{ padding: '5px 40px' }}
                        disabled={this.state.onDisable}
                      >
                        On
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => this.onConfirm('off')}
                        style={{ padding: '5px 40px' }}
                        disabled={this.state.offDisablee}
                      >
                        Off
                      </button>
                    </div>
                  </Modal>
                )}
              </div>
            )}
            <div className="d-flex">
              {dateFilter && (
                <DateTimePicker
                  start={fromTime}
                  end={toTime}
                  onChange={this.dateChange}
                />
              )}
              {searchOpen && (
                <form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="position-relative">
                    <InputBase
                      className="form-control"
                      value={search}
                      onChange={(e) => {
                        onChange('search', e.target.value);
                      }}
                      placeholder="Search..."
                    />
                    <span className="fa fa-search"></span>
                  </div>
                </form>
              )}

              <div className="dropdown d-none d-lg-inline-block">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen"></i>
                </button>
              </div>
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}
const mapDispatchtoProps = (state) => {
  const { error, loading, data: userPumpList } = state.Pump;
  return { error, loading, userPumpList };
};

export default withRouter(connect(mapDispatchtoProps, { userPump })(TopBar));
