import { decimal } from 'helpers';
import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
class TileMinMax extends Component {
  render() {
    const {
      discharge,
      efficiency,
      head,
      output,
      power,
      speed,
      current,
    } = this.props.data;
    const tileData = [
      {
        title: 'Current',
        minValue: current?.min,
        maxValue: current?.max,
        curr_value: current?.latest,
        unit: 'A',
      },
      {
        title: 'Pump Head',
        minValue: head?.min,
        maxValue: head?.max,
        curr_value: head?.latest,
        unit: 'm',
      },
      {
        title: 'Pump Discharge',
        minValue: discharge?.min,
        maxValue: discharge?.max,
        curr_value: discharge?.latest,
        unit: 'Lps',
      },

      {
        title: 'Input Power',
        minValue: power?.min,
        maxValue: power?.max,
        curr_value: power?.latest,
        unit: 'kW',
      },
      {
        title: 'Output Power',
        minValue: output?.min,
        maxValue: output?.max,
        curr_value: output?.latest,
        unit: 'kW',
      },
      {
        title: 'System Efficiency',
        minValue: efficiency?.min,
        maxValue: efficiency?.max,
        curr_value: efficiency?.latest,
        unit: '%',
      },
      {
        title: 'Motor Speed',
        minValue: speed?.min,
        maxValue: speed?.max,
        curr_value: speed?.latest,
        unit: 'RPM',
      },
    ];
    return (
      <React.Fragment>
        {tileData &&
          tileData.map((i, index) => {
            return (
              <Card
                className="mini-stat bg-primary text-white"
                style={{ width: '270px' }}
                key={index}
              >
                <CardBody>
                  <div>
                    <h4 style={{ color: '#fff' }}>{i.title}</h4>
                    <div>
                      <p
                        style={{
                          fontSize: '30px',
                          marginBottom: 0,
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                          display: 'flex',
                          marginTop: '-5px',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '16px',
                            marginTop: '12px',
                            marginRight: '40px',
                          }}
                        >
                          ({i.unit})
                        </div>
                        {decimal(i.curr_value)}
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                    }}
                  >
                    <p>
                      {decimal(i.minValue)}
                      <i
                        className="mdi mdi-arrow-down text-white ml-2"
                        style={{ fontSize: '16px' }}
                      ></i>
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {decimal(i.maxValue)}
                      <i
                        className="mdi mdi-arrow-up text-white ml-2"
                        style={{ fontSize: '16px' }}
                      ></i>
                    </p>
                  </div>
                </CardBody>
              </Card>
            );
          })}
      </React.Fragment>
    );
  }
}
export default TileMinMax;
