export const column = [
  {
    label: 'Pump Name',
    field: 'pump_name',
  },
  {
    label: 'Message',
    field: 'error_message',
  },
  {
    label: 'Triggered At',
    field: 'created_at',
  },
];
