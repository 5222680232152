import makeRequest from ".";
import {apiHost} from "../config.js";
import {headers} from "../helpers";

function generateParams({search = ""} = {}) {
  return `&query=${search}`;
}

export function fetchUser(requestParams) {
  return makeRequest({
    uri: `/api/v1/users?${generateParams(requestParams)}`,
  });
}
export function createUser(requestBody) {
  return makeRequest({
    uri: "/api/v1/users",
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateUser(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/users/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}
export function updateProfile(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/users/profile`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}
export function updatePassword(requestBody) {
  return makeRequest({
    uri: `/api/v1/users/update_password`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function userProfile() {
  return makeRequest({
    uri: `/api/v1/users/profile`,
  });
}

export function logOut(id) {
  return fetch(apiHost + `/api/v1/users/${id}/invalidate`, {
    method: "PUT",
    headers: headers(),
  }).then(async (response) => {
    if (response.ok) {
      return "Logout Successfully";
    }
    throw new Error([response.status, await response.json()]);
  });
}

export function fetchRoleGroup() {
  return makeRequest({
    uri: `/api/v1/role_groups`,
    method: "GET",
  });
}

export function fetchRoles() {
  return makeRequest({
    uri: `/api/v1/users/roles`,
    method: "GET",
  });
}
