import React, { Component } from 'react';
import { Row, Card, CardBody } from 'reactstrap';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  fetchSummary,
  getTileDetail,
  liveData,
  getPumpData,
} from '../../api/pumps';
import {
  Button,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import { column } from '../../data/pumpDetails';
import { timeDiff, decimal } from 'helpers';
import moment from 'moment';
import LineApexChart from './chartapex';
import { handleResponse } from 'components/handleResponse';
import Layout from 'components/VerticalLayout';
import ToggleSwitch from 'components/ToggleSwitch';
import { userPump } from 'store/pump/actions';
import { TileMinMax, DateTimePicker } from 'components';
import { graphRange } from 'config';
class PumpDetails extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      graphData: [],
      pumpData: {},
      tileData: {},
      pumpList: [],
      liveDataList: [],
      table: [],
      summarizeTableData: [],
      range: graphRange[0],
      device: {
        label: localStorage.getItem('pump_name'),
        value: localStorage.getItem('pump_id'),
        device_id: localStorage.getItem('device_id'),
      },
      live: false,
    };
    this.requestParams = this.initialRequestParams();
  }

  initialRequestParams = () => {
    return {
      fromTime: moment.utc(moment().subtract(12, 'hours')).format(),
      toTime: moment.utc(moment()).format(),
    };
  };

  formatValues = (obj = {}) => {
    [
      'output',
      'efficiency',
      'power',
      'current',
      'discharge',
      'frequency',
      'voltage',
      'head',
    ].forEach((field) => {
      obj[field] = decimal(obj[field]);
    });
    return obj;
  };

  fetchSummaryData = (id) => {
    fetchSummary(id, this.requestParams)
      .then(({ data }) => {
        let formattedData = (data || []).map((obj) => {
          obj = this.formatValues(obj);
          obj['formated_time'] =
            moment(obj['summary_date']).format('DD MMM YYYY ') +
            obj['summary_time'].slice(0, 5);
          return obj;
        });
        this.setState({
          graphData: formattedData,
          summarizeTableData: [...formattedData].reverse(),
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  constructData = (data, minutes = 5) => {
    const current_time = parseInt(moment().format('X'));
    const start_time = current_time - minutes * 60;
    const end_time = current_time;
    const periodic_value = 300; //300 sec
    const range = periodic_value + 10; //10sec

    const output = [];
    for (let i = start_time; i < end_time; i = i + periodic_value) {
      let insert_new_value = true;
      data = data.map((obj) => {
        if (!obj['processed']) {
          const time_in_ms = moment(obj.created_at).format('X');
          if (time_in_ms - range <= i && i <= time_in_ms + range) {
            obj['formated_time'] = moment(obj.created_at).format('hh:mm:ss');
            output.push(this.formatValues(obj));
            obj['processed'] = true;
            insert_new_value = false;
          }
        }
        return obj;
      });
      if (insert_new_value) {
        this.formatValues();
        const obj = this.formatValues();
        obj['formated_time'] = moment.unix(i).format('hh:mm:ss');
        output.push(obj);
      }
    }
    return output;
  };

  fetchLiveData = (id, duration) => {
    liveData(id, duration)
      .then(({ data }) => {
        const formattedData = this.constructData([...data.reverse()], duration);
        this.setState({
          table: [...formattedData].reverse(),
          liveDataList: formattedData,
        });
      })

      .catch((error) => {
        console.log(error);
        handleResponse(error);
      });
  };

  fetchPumpDetailData = (id) => {
    getPumpData(id)
      .then(({ data: pumpData }) => {
        this.setState({ pumpData });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchTileData = (id) => {
    getTileDetail(id)
      .then(({ data: tileData }) => {
        this.setState({ tileData });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  toggleChange = (e) => {
    this.setState({ live: e.target.checked }, () => {
      this.handleFetchAllData();
    });
  };

  onDateChange = (from_time, to_time) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    const pumpId = localStorage.getItem('pump_id');
    this.fetchSummaryData(pumpId);
  };

  handleSelect = (device) => {
    const { value: pumpId, label, device_id } = device;
    localStorage.setItem('pump_id', pumpId);
    localStorage.setItem('pump_name', label);
    localStorage.setItem('device_id', device_id);
    this.props.history.push(
      device_id ? `/pump/${device_id}/details` : `/dashboard`
    );
    this.setState({ device }, () => this.handleFetchAllData());
  };

  handleLiveDurationChange = (range) => {
    this.setState({ range });
    this.handleFetchAllData(range.value);
  };

  handleFetchAllData = (liveDuration) => {
    const pumpId = localStorage.getItem('pump_id');
    if (!pumpId) {
      this.props.history.push(`/dashboard`);
    }
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    const { value: duration } = this.state.range;
    this.timer = setInterval(() => {
      this.fetchTileData(pumpId);
      this.fetchPumpDetailData(pumpId);
      if (this.state.live) {
        this.fetchLiveData(pumpId, liveDuration || duration);
      }
    }, 15000);

    //Default
    this.fetchTileData(pumpId);
    this.fetchPumpDetailData(pumpId);
    // live
    if (this.state.live) {
      this.fetchLiveData(pumpId, liveDuration || duration);
    } else {
      // Periodic
      this.fetchSummaryData(pumpId);
    }
  };

  componentDidMount = async () => {
    const { userPumpList } = this.props;
    userPumpList.length === 0 && await this.props.userPump();
    this.handleFetchAllData();
  }

  componentDidUpdate = () => {
    if (!localStorage.getItem('pump_id')) {
      this.props.userPump()
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  render() {
    const {
      onDateChange,
      handleSelect,
      toggleChange,
      handleLiveDurationChange,
    } = this;
    const {
      pumpData,
      graphData,
      device,
      live,
      tileData,
      range,
      liveDataList,
      table,
      summarizeTableData,
    } = this.state;
    let summaryTableData = {
      columns: column('summary'),
      rows: summarizeTableData,
    };
    let pumpTableData = {
      columns: column('live'),
      rows: table,
    };

    const graphProps = {
      graphData,
      key: 'summary',
    };
    const pdGraph = {
      graphData: liveDataList,
      key: 'pump',
      range: range?.value,
    };

    const appProps = {
      dateFilter: /* !toggle ? true : */ false,
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: false,
      onDateChange,
      select: true,
      handleSelect,
      device,
      stateDetails: true,
      state: pumpData?.state,
      controlActivity: true,
    };
    const toggleProps = {
      leftString: 'Recorded',
      rightString: 'Live',
      toggleChange,
      toggle: live,
    };
    return (
      <Layout {...appProps}>
        <div className="container-fluid">
          <Row
            className="align-items-center"
            style={{
              padding: '12px',
              marginBottom: '10px',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
                marginRight: '10px',
                padding: '10px',
              }}
            >
              <div>
                <ToggleSwitch {...toggleProps} />
              </div>
            </div>
            {!live && (
              <DateTimePicker
                start={moment(this.requestParams.fromTime)}
                end={moment(this.requestParams.toTime)}
                onChange={this.onDateChange}
              />
            )}
            <div>
              {live && (
                <div className="btn-group mb-2">
                  <ButtonDropdown
                    isOpen={this.state.drp_primary1}
                    toggle={() =>
                      this.setState({
                        drp_primary1: !this.state.drp_primary1,
                      })
                    }
                  >
                    <Button id="caret" color="primary">
                      {range?.label}
                    </Button>
                    <DropdownToggle caret color="primary">
                      <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      {(graphRange || []).map((obj) => {
                        return (
                          <DropdownItem
                            value="action"
                            onClick={() => handleLiveDurationChange(obj)}
                          >
                            {obj.label}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              )}
            </div>

            {pumpData?.last_pump_detail && (
              <div className="page-title-box">
                <span>Last Active at</span>
                <h4 className="font-size-16">
                  {pumpData?.last_pump_detail
                    ? timeDiff(moment(), pumpData?.last_pump_detail)
                    : 'No Data'}
                </h4>
                <ol className="breadcrumb mb-0"></ol>
              </div>
            )}
          </Row>
          <Row style={{ justifyContent: 'space-around' }}>
            <TileMinMax data={tileData} />
          </Row>

          <Row>
            <div style={{ width: '100%' }}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">
                    {!live ? `Summary Graph` : `Pump Graph`}
                  </h4>
                  <LineApexChart data={!live ? graphProps : pdGraph} />
                </CardBody>
              </Card>
            </div>
          </Row>
          <Row>
            <div style={{ width: '100%' }} /* className="col-12" */>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    {!live ? `Summary Details` : `Pump Details`}
                  </h4>
                  <MDBDataTable
                    style={{ textAlign: 'center' }}
                    responsive
                    striped
                    bordered
                    noBottomColumns
                    paging={true}
                    entries={50}
                    data={!live ? summaryTableData : pumpTableData}
                  />
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Layout>
    );
  }
}

const mapDispatchtoProps = (state) => {
  const { error, loading, data } = state.Pump;
  return { error, loading, userPumpList: data };
};

export default withRouter(
  connect(mapDispatchtoProps, { userPump })(PumpDetails)
);
