import moment from 'moment';
import { lastLogin } from '../api/login';

function getCookie(name) {
  let cookie = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return cookie && cookie[2];
}

function setCookie(name, value, days = 0, hours = 0) {
  let date = new Date();
  if (days || hours) {
    date.setTime(
      date.getTime() + hours * 60 * 60 * 1000 + 24 * 60 * 60 * 1000 * days
    );
    document.cookie =
      name + '=' + value + ';path=/;expires=' + date.toGMTString();
  } else {
    document.cookie = name + '=' + value + ';path=/;';
  }
}

function popCookie(name) {
  let value = getCookie(name);
  deleteCookie(name);
  return value;
}

// function deleteCookie(name) {
//   setCookie(name, '', -1);
// }

function deleteCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function deleteAllCookie() {
  deleteCookie('token');
  deleteCookie('pump_id');
  deleteCookie('first_name');
  deleteCookie('last_name');
  deleteCookie('email');
  deleteCookie('device_id');
  deleteCookie('user_id');
  deleteCookie('active');

  // document.cookie.split(';').forEach(function (c) {
  //   document.cookie = c
  //     .replace(/^ +/, '')
  //     .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  // });
}

async function kickUser() {
  await deleteAllCookie();
  await window.localStorage.clear();
  await window.location.reload();
}

const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function isArray(a) {
  return !!a && a.constructor === Array;
}

function isObject(a) {
  return !!a && a.constructor === Object;
}

function titleize(str) {
  if (!str) {
    return str;
  }

  return str
    .split(' ')
    .map(function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(' ')
    .split('_')
    .map(function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(' ');
}

function isAuthenticated() {
  return !!getCookie('token');
}

const headers = (formData = false) => {
  if (!getCookie('active')) {
    lastLogin();
  }

  return formData
    ? {
      Authorization: getCookie('token'),
    }
    : {
      ...jsonHeaders,
      Authorization: getCookie('token'),
    };
};

function timeDiff(startTime, endTime) {
  if (!startTime || !endTime) return '-';

  if (startTime < endTime)
    return moment.duration(moment(endTime).diff(moment(startTime))).humanize();
  else
    return moment.duration(moment(endTime).diff(moment(startTime))).humanize(1);
}

function formatTime(time, format) {
  if (!time) return '-';

  format = format || 'DD-MM-YYYY HH:mm:ss';
  return moment(time).format(format);
}

function presence(string) {
  return string ? string : '-';
}

function noValue(number) {
  return number ? number : 0;
}

function keyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const truncate = (input, limit) => {
  if (!input) return;
  return input.length > limit ? `${input.substring(0, limit - 5)}...` : input;
};

function formatDate(date) {
  return moment(date).utc().format('YYYY-MM-DD H:mm:ss') + '+0000';
}

function readParseLS(key) {
  try {
    const data = JSON.parse(window.localStorage.getItem(key));
    if (!data || 'object' !== typeof data) {
      return;
    }
    return data;
  } catch (err) {
    return;
  }
}

function presentationAccess() {
  let user_roles = window.localStorage.getItem('user_roles');
  user_roles = user_roles && JSON.parse(user_roles);

  let sub_permissions = user_roles && user_roles['api/v1/alerts'];
  let presentations = user_roles && user_roles['api/v1/presentations'];
  return (
    sub_permissions.includes('aggs') &&
    sub_permissions.includes('index') &&
    presentations.includes('index')
  );
}

function setHours(datetime, type) {
  datetime = datetime ? new Date(datetime) : new Date();
  let response = undefined;
  switch (type) {
    case 'START':
      response = new Date(datetime).setHours(0, 0, 0, 0);
      break;
    case 'END':
      response = new Date(datetime).setHours(23, 59, 59, 999);
      break;
    default:
      response = new Date(datetime);
      break;
  }
  return response;
}

const rmSpecialCharacterCaptalize = (type) => {
  type = type && type.replace(/\./g, ' ');
  type = type && type.replace(/_/g, ' ');
  type =
    type &&
    type.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  return type;
};

function generateDateTime(datetime) {
  datetime = datetime ? new Date(datetime) : new Date();
  let arr = [];
  let start_value = setHours(datetime, 'START');
  let end_value = setHours(datetime, 'END');
  arr.push(formatDate(start_value));
  for (let i = 1; i <= 5; i++) {
    arr.push(formatDate(datetime.setHours(i * 4, 0, 0, 0)));
  }
  arr.push(formatDate(end_value));
  return arr;
}

function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

function removeSpecialChar(str, arg) {
  return str.replace(/[^a-zA-Z 0-9]+/g, arg);
}

function splitWords(str, arg) {
  return str.split(arg);
}

function varHumanize(str) {
  var a = removeSpecialChar(str, ' ');
  var words = splitWords(a, ' ');
  var humanize = '';
  words.forEach((word) => {
    humanize += capitalizeFirstLetter(word) + ' ';
  });
  return humanize;
}

function compareTwoArray(array1, array2) {
  if (!array1 || !array2) return;

  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  );
}

function rounfOffTime(to_time) {
  if (!to_time) return;
  const formated_time = 0 - (to_time?.minute() % 30);

  const Time = moment(to_time).add(formated_time, 'minutes');
  return Time;
}

function decimal(val) {
  let res = parseFloat(val || 0).toFixed(2);
  return res;
}

export {
  decimal,
  getCookie,
  setCookie,
  deleteCookie,
  popCookie,
  jsonHeaders,
  shuffleArray,
  isArray,
  isObject,
  titleize,
  isAuthenticated,
  headers,
  timeDiff,
  formatTime,
  presence,
  keyByValue,
  truncate,
  formatDate,
  readParseLS,
  setHours,
  generateDateTime,
  capitalizeFirstLetter,
  removeSpecialChar,
  splitWords,
  varHumanize,
  compareTwoArray,
  kickUser,
  noValue,
  presentationAccess,
  rmSpecialCharacterCaptalize,
  debounce,
  deleteAllCookie,
  rounfOffTime,
};
