import React, { Component, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Login, ForgotPassword, Profile, ResetPassword } from 'pages/Views';
import { isAuthenticated } from 'helpers';
import { filteredMenu } from 'data/menus';
import NonAuthLayout from 'components/NonAuthLayout';

export default class CustomRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSlider: [],
      loadComponent: false,
      defaultRoute: window.location.pathname,
      routes: [
        {
          index: '1',
          url: () => `/profile`,
          match_url: '/profile',
          render: (props) => <Profile {...props} />,
          slider: false,
          slideShow: false,
        },
      ],
    };
  }

  async componentDidMount() {
    if (isAuthenticated()) {
      let defaultRoute;
      let { routes } = this.state;
      let showSlider = routes.filter((o) => o.slider).map((o) => o.match_url);
      let menuGroups = await filteredMenu();
      menuGroups.forEach((menu) => {
        routes.push(menu);
        showSlider.push(menu.match_url);
        if (!defaultRoute) {
          defaultRoute = menu.match_url;
        }
      });
      this.setState({
        routes,
        showSlider,
        defaultRoute: defaultRoute || this.state.defaultRoute,
        loadComponent: true,
      });
    }
  }

  render() {
    const { defaultRoute, routes } = this.state;
    return (
      <Switch>
        <PublicRoute
          exact
          path="/"
          component={Login}
          defaultRoute={defaultRoute}
        />
        <PublicRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
          defaultRoute={defaultRoute}
        />
        <PublicRoute
          path="/reset-password"
          component={ResetPassword}
          defaultRoute={defaultRoute}
        />

        {this.state.loadComponent &&
          routes.map((route, i) => (
            <PrivateRoute
              showSlider={route.slideShow}
              key={i}
              exact
              path={route.match_url}
              index={route.index}
              component={route.render}
            />
          ))}
        <Route
          component={() => {
            return isAuthenticated() ? (
              <Redirect to={defaultRoute} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
      </Switch>
    );
  }
}

const PrivateRoute = ({ component: Component, showSlider, ...rest }) => {
  useEffect(() => {}, []);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          showSlider ? (
            <div>{Component(props)}</div>
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const PublicRoute = ({ defaultRoute, component: Component, ...rest }) => {
  useEffect(() => {}, []);

  return (
    <NonAuthLayout>
      <Route
        {...rest}
        render={(props) => {
          return isAuthenticated() ? (
            <Redirect to={defaultRoute} />
          ) : (
            <Component {...props} />
          );
        }}
      />
    </NonAuthLayout>
  );
};
