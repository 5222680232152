import React from 'react';
import IconEnergy from 'assets/images/Logo/footer/Icon_energy.png';
import IconGef from 'assets/images/Logo/footer/Icon_gef.png';
import IconFlctd from 'assets/images/Logo/footer/Icon_flctd.png';
import IconUnitd from 'assets/images/Logo/footer/Icon_unido.png';
import IconLen from 'assets/images/Logo/footer/Icon_len.png';

const Footer = () => {
  const imgStyle = {
    marginTop: '5px',
    marginRight: '60px',
    height: '50px',
    mixBlendMode: "multiply"
  };
  return (
    <React.Fragment>
      <footer className="footer" style={{ position: 'fixed', zIndex: 10 }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12" style={{ marginTop: '6px' }}>
              <img
                src={IconEnergy}
                width="60px"
                style={{ ...imgStyle }}
                alt=""
              />
              <img src={IconGef} width="60px" style={{ ...imgStyle }} alt="" />
              <img
                src={IconFlctd}
                width="100px"
                style={{ ...imgStyle }}
                alt=""
              />
              <img
                src={IconUnitd}
                width="60px"
                style={{ ...imgStyle }}
                alt=""
              />
              <img src={IconLen} width="120px" style={{ ...imgStyle }} alt="" />
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
