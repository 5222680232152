import React, { Component } from 'react';
import { userProfile } from '../../api/users';
import Form from '../Forms/user/ProfileForm';
import 'chartist/dist/scss/chartist.scss';
import { handleResponse } from '../../components/handleResponse';
import Layout from 'components/VerticalLayout';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: [],
      formOpen: false,
    };
  }

  fetchUserData = () => {
    userProfile()
      .then(({ data }) => {
        this.setState({
          profile: data,
          formOpen: true,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount() {
    this.fetchUserData();
  }

  onCancel = () => {
    this.setState({ formOpen: false });
    this.props.history.push('/dashboard');
  };

  handleSubmitSuccess = () => {
    this.onCancel();
  };

  render() {
    const { profile, formOpen } = this.state;
    const { onCancel, handleSubmitSuccess } = this;
    const props = { ...profile, onCancel, handleSubmitSuccess };
    const appProps = {
      dateFilter: false,
      search: false,
    };
    return (
      <React.Fragment>
        <Layout {...appProps}>{formOpen && <Form data={props} />}</Layout>
      </React.Fragment>
    );
  }
}

export default Profile;
