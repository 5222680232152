import React from 'react';

const ToggleSwitch = (props) => {
  const { leftString, rightString, toggleChange, toggle } = props;
  return (
    <div
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex',
        width: '180px',
        verticalAlign: 'center',
        marginTop: '5px',
      }}
    >
      <h6 style={{ marginTop: '5px' }}>{leftString}</h6>
      <label className="switch">
        <input
          type="checkbox"
          checked={toggle}
          onChange={(e) => {
            toggleChange(e);
          }}
        />
        <span className="check round"></span>
      </label>
      <h6 style={{ marginTop: '5px' }}>{rightString}</h6>
    </div>
  );
};
export default ToggleSwitch;
