import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

const PARAMETERS = [
  'Current (A)',
  'Pump Head (m)',
  'Pump Discharge (Lps)',
  'System Efficiency (%)',
  'Input Power (kW)',
  'Output Power (kW)',
];

const NameValue = (param) => {
  switch (param) {
    case 'Current (A)':
      return 'current';
    case 'Pump Head (m)':
      return 'head';
    case 'Pump Discharge (Lps)':
      return 'discharge';
    case 'System Efficiency (%)':
      return 'efficiency';
    case 'Output Power (kW)':
      return 'output';
    case 'Input Power (kW)':
      return 'power';
    default:
      break;
  }
};
class chartapex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      options: CHART_OPTIONS,
      series: [],
    };
  }

  componentDidUpdate = () => {
    const { data } = this.props;
    const { key, graphData } = data;
    const series = PARAMETERS.map((param) => ({
      name: param,
      data: [],
    }));
    if (data !== this.state.data) {
      let categories = [];
      graphData &&
        graphData.forEach((summary) => {
          PARAMETERS.forEach((param, index) => {
            const value = summary[NameValue(param)] || 0;
            series[index]['data'].push(
              parseFloat(parseFloat(value).toFixed(3))
            );
          });
          const { summary_date, summary_time, formated_time } = summary;
          if (key === 'summary') {
            categories.push(
              moment(summary_date + ' ' + summary_time).format('DD/MM HH:mm')
            );
          } else {
            categories.push(formated_time);
          }
        });
      this.setState({
        series,
        options: {
          ...this.state.options,
          xaxis: { ...this.state.options.xaxis, categories },
        },
        data,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height="350"
        />
      </React.Fragment>
    );
  }
}

export default chartapex;

const CHART_OPTIONS = {
  colors: [
    '#ccc',
    '#3c4ccf',
    '#02a499',
    '#AACA08',
    '#1087EA',
    '#02a000',
    '#fb00ff',
    '#fff982',
    '#545454',
  ],
  chart: {
    zoom: {
      enabled: true,
    },
    toolbar: {
      show: true,
    },
  },
  legend: {
    show: true,
  },

  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  grid: {
    borderColor: '#f8f8fa',
    row: {
      colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: [],
    axisBorder: {
      show: true,
    },
    axisTicks: {
      show: true,
    },
  },
};
