import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import MetisMenu from 'metismenujs';

import SimpleBar from 'simplebar-react';
import { filteredMenu } from '../../data/menus';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuData: [],
    };
  }

  async componentDidMount() {
    let data = await filteredMenu();
    this.setState({ menuData: data });
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.setState(this.state);
    }
  }

  initMenu = () => {
    if (this.props.type !== 'condensed' || this.props.isMobile) {
      new MetisMenu('#side-menu');

      var matchingMenuItem = '';
      var ul = document.getElementById('side-menu');
      var items = ul.getElementsByTagName('a');
      for (var i = 0; i < items.length; ++i) {
        if (this.currentPath === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  };

  activateParentDropdown = (item) => {
    item.classList.add('mm-active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active'); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    const { menuData } = this.state;
    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: '100%' }}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {menuData &&
                menuData.map(({ show, url, match_url, icon, name }, i) => {
                  if (!show) {
                    return '';
                  }
                  const active = match_url === this.props.match.path;
                  const activeClassName = active ? 'mm-active' : '';
                  let pump_id = localStorage.getItem('device_id');
                  return (
                    <li className={activeClassName} key={i}>
                      <Link
                        to={() =>
                          url(
                            name === 'Pump Details' || 'Live' || 'Error Logs'
                              ? pump_id
                              : ''
                          )
                        }
                        className={'waves-effect ' + activeClassName}
                      >
                        <i className={icon}></i>
                        <span>{name}</span>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

export default withRouter(Sidebar);
