import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import { statusSelect, pumpCategory } from "../../../config";
import { createPump, updatePump } from "../../../api/pumps";
import { capitalizeFirstLetter } from "helpers";
import { success, handleResponse } from "../../../components/handleResponse";
import Layout from "components/VerticalLayout";

class PumpForm extends Component {
  constructor(props) {
    super(props);
    this.id = null;
    this.state = {
      data: {
        name: "",
        device_id: "",
        power: 0,
        status: statusSelect[0],
        user_ids: [],
        category: pumpCategory[0],
      },
    };
  }

  handleChange = (e) => {
    let { data } = this.state;
    let { name, value } = e.target;
    data[name] = value;
    this.setState({ data });
  };

  handleSelect = (e, name) => {
    let { data } = this.state;
    data[name] = e;
    this.setState({ data });
  };

  handleMultiSelect = (e) => {
    let { data } = this.state;
    data["user_ids"] = e;
    this.setState({ data });
  };

  onSubmit = () => {
    const { user_ids, category, status, ...rest } = this.state.data;
    let requestValue = {
      ...rest,
      category: category.value,
      user_ids: user_ids.map((val) => val.value),
    };
    const action = this.id ? updatePump : createPump;
    action(this.id, requestValue)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    if (this.props.pumpData) {
      const { id, name, device_id, status, users, power, category } =
        this.props.pumpData;
      this.id = id;
      const { pumpData } = this.props;
      let selectedUser = this.props.userData.filter((user) => user.superAdmin);
      if (pumpData) {
        pumpData &&
          users &&
          users.forEach((val) => {
            selectedUser.push({ label: val.first_name, value: val.id });
          });
      }

      const categories = pumpCategory.find((x) => (x.value === category));
      this.setState({
        data: {
          ...this.state.data,
          name,
          device_id,
          power,
          status: status && {
            label: capitalizeFirstLetter(status),
            value: status,
          },
          category: categories,
          user_ids: selectedUser,
        },
      });
    }
  };

  render() {
    const { onCancel, userData } = this.props;
    const { handleChange, handleMultiSelect, handleSelect, id } = this;
    const { name, device_id, user_ids, power, category } = this.state.data;
    return (
      <React.Fragment>
        <Layout>
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">{`${
                    !id ? "Create Pump" : "Update Pump"
                  }`}</h4>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <AvForm>
                      <AvField
                        name="name"
                        label="Name *"
                        placeholder={!id ? "Name" : null}
                        value={name}
                        onChange={handleChange}
                        type="text"
                        errorMessage="Enter Name"
                        validate={{ required: { value: true } }}
                      />
                      <AvField
                        name="device_id"
                        label="Device ID *"
                        placeholder={!id ? "Device ID" : null}
                        value={device_id}
                        onChange={handleChange}
                        type="text"
                        errorMessage="Enter Device ID"
                        validate={{ required: { value: true } }}
                      />
                      <AvField
                        name="power"
                        label="Power (in HP)"
                        placeholder={!id ? "Power" : null}
                        value={power}
                        onChange={handleChange}
                        type="text"
                        errorMessage="Enter Power"
                        disabled={id ? true : false}
                        validate={{
                          pattern: {
                            value: "^[0-9]*(.[0-9]+)?$",
                            errorMessage: "Only Numbers",
                          },
                        }}
                      />
                      <Form>
                        {/* <FormGroup>
                          <Label>Status</Label>
                          <Select
                            isDisabled={id ? 'true' : false}
                            value={status}
                            onChange={(e) => {
                              handleSelect(e, 'status');
                            }}
                            options={statusSelect}
                          />
                        </FormGroup> */}
                        <FormGroup>
                          <Label>Users</Label>
                          <Select
                            maxMenuHeight={160}
                            value={user_ids}
                            isMulti={true}
                            onChange={(e) => {
                              handleMultiSelect(e);
                            }}
                            options={userData}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Controller Category *</Label>
                          <Select
                            required
                            value={category}
                            onChange={(e) => {
                              handleSelect(e, "category");
                            }}
                            options={pumpCategory}
                          />
                        </FormGroup>
                      </Form>
                      <FormGroup>
                        <Button
                          type="submit"
                          color="primary"
                          className="mr-1"
                          onClick={
                            !id
                              ? this.onSubmit
                              : () => {
                                  this.onSubmit(id);
                                }
                          }
                        >
                          Submit
                        </Button>{" "}
                        <Button
                          type="reset"
                          color="secondary"
                          onClick={onCancel}
                        >
                          Cancel
                        </Button>
                      </FormGroup>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default PumpForm;
