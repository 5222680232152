import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import servicesIcon1 from 'assets/images/services-icon/01.png';
import { rmSpecialCharacterCaptalize, timeDiff } from 'helpers';
import moment from 'moment';

class DashBoardPumpTile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => { };

  render() {
    const { data, onClick } = this.props;
    return (
      <React.Fragment>
        {data && data.length > 0 &&
          data[0].map((pump, i) => (
            <Card
              className="mini-stat bg-primary text-white"
              onClick={() => {
                onClick(pump.id, pump.device_id, pump.name);
              }}
              style={{
                cursor: 'pointer',
                width: '310px',
                marginRight: '2%',
              }}
              key={i}
            >
              <CardBody>
                <div className="mb-4">
                  <div className="float-left mini-stat-img mr-4">
                    <img src={servicesIcon1} alt="" />
                  </div>
                  <h5 className="font-size-12 text-uppercase mt-0 text-white-50">
                    {pump.device_id}
                  </h5>
                  <h4
                    className="font-weight-medium font-size-20"
                    style={{ color: '#fff' }}
                  >
                    {pump.name}
                  </h4>
                  <div
                    className={
                      pump.state === 'on'
                        ? 'mini-stat-label bg-success'
                        : pump.state === 'lost'
                          ? 'mini-stat-label bg-warning'
                          : 'mini-stat-label bg-danger'
                    }
                  >
                    <p className="mb-0">
                      {rmSpecialCharacterCaptalize(pump.state)}
                    </p>
                  </div>
                </div>
                <div className="pt-2">
                  <div className="float-right">
                    <Link to="#" className="text-white-50">
                      <i className="mdi mdi-arrow-right h5"></i>
                    </Link>
                  </div>
                  {pump.last_updated_at && (
                    <p className="text-white-50 mb-0 mt-1">
                      Last Active {timeDiff(moment(), pump.last_updated_at)}
                    </p>
                  )}
                </div>
              </CardBody>
            </Card>
          ))}
      </React.Fragment>
    );
  }
}
export default DashBoardPumpTile;
