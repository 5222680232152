import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { PumpTile } from 'components';
import { fetchUserPump } from '../../api/pumps';
import { fetchUser } from '../../api/users';
import Form from '../Forms/pump/Form';
import { handleResponse } from '../../components/handleResponse';
import Layout from 'components/VerticalLayout';
import { debounce } from 'helpers';

import 'chartist/dist/scss/chartist.scss';

class Pumps extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      formOpen: false,
      pumpData: [],
      userData: [],
    };
  }

  initialRequestParams = () => {
    return {
      search: '',
    };
  };

  fetchPumpData = () => {
    fetchUserPump(this.requestParams.search)
      .then(({ data }) => {
        this.setState({
          data: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchUserData = () => {
    fetchUser()
      .then(({ data }) => {
        this.setState({
          userData: data.map((user) => ({
            label: user.first_name,
            value: user.id,
            superAdmin: user.role_group.name === 'Super Admin',
          })),
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  pumpAdd = (data) => {
    this.setState({
      formOpen: true,
      pumpData: data,
    });
  };

  onCancel = () => {
    this.setState({ formOpen: false });
  };

  componentDidMount() {
    this.fetchPumpData();
    this.fetchUserData();
  }

  handleSubmitSuccess = () => {
    this.fetchPumpData();
    this.onCancel();
    this.fetchUserData();
  };

  debounceSearch = debounce(() => {
    this.fetchPumpData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.setState(this.state);
    this.setState(this.state);
    this.debounceSearch();
  };

  render() {
    let usr_roles = JSON.parse(window.localStorage.getItem('user_roles'));
    let add = usr_roles['api/v1/pumps'].includes('create');
    let update = usr_roles['api/v1/pumps'].includes('update');
    const { data, formOpen, pumpData, userData } = this.state;
    const { pumpAdd, onCancel, handleSubmitSuccess, onChange } = this;
    const updateProps = {
      data,
      onCancel,
      formOpen,
      pumpAdd,
      pumpData,
      userData,
      handleSubmitSuccess,
    };

    if (update && formOpen) {
      return <Form {...updateProps} />;
    }

    const TopBarProps = {
      dateFilter: false,
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };

    return (
      <React.Fragment>
        <Layout {...TopBarProps}>
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Pumps</h4>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item active">Pumps List</li>
                  </ol>
                </div>
              </Col>
              {add && (
                <Col sm="6">
                  <div className="float-right d-none d-md-block">
                    <Button
                      type="submit"
                      color="primary"
                      className="mr-1"
                      onClick={pumpAdd}
                    >
                      <i
                        className=" mdi mdi-hospital"
                        style={{ marginRight: '10px' }}
                      ></i>
                      Add Pump
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
            <Row style={{ marginLeft: '1%' }}>
              <PumpTile {...updateProps} />
            </Row>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default Pumps;
