import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Button,
  Label,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';
import { statusSelect } from '../../../config';
import { createUser, updateUser } from '../../../api/users';
import { capitaliseFirstLetter } from 'fullcalendar';
import { success, handleResponse } from '../../../components/handleResponse';
import Layout from 'components/VerticalLayout';
import { getCookie, setCookie } from 'helpers';

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.id = null;
    this.state = {
      data: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        pump_ids: [],
        role_group: [],
        status: statusSelect[0],
      },
      adminAccessData: [],
      admin: getCookie('adminAccess') === 'Admin',
      superAdmin: false,
    };
  }

  handleChange = (e) => {
    let { data } = this.state;
    let { name, value } = e.target;
    data[name] = value;
    this.setState({ data });
  };

  handleSelect = (e, name) => {
    let { data, superAdmin } = this.state;
    data[name] = e;
    if (name === 'role_group') {
      if (e.label === 'Super Admin') {
        superAdmin = true;
      } else {
        superAdmin = false;
      }
    }
    this.setState({ data, superAdmin });
  };

  handleMultiSelect = (e) => {
    let { data } = this.state;
    data['pump_ids'] = e;
    this.setState({ data });
  };

  onSubmit = async () => {
    let user_id = await getCookie('user_id');
    const { pumpData } = this.props;
    let roleSA = (pumpData && pumpData.map((val) => val.value)) || [];
    const { pump_ids, status, role_group, ...rest } = this.state.data;
    let requestValue = {
      users: {
        ...rest,
        pump_ids: this.state.superAdmin
          ? roleSA
          : (pump_ids && pump_ids.map((val) => val.value)) || [],
        status: status && status.value,
        role_group_id: role_group.value,
      },
    };

    const action = this.id ? updateUser : createUser;
    action(requestValue, this.id)
      .then(
        ({
          data: { id, first_name, last_name, email, auth_token },
          message,
        }) => {
          if (id && id === user_id) {
            id && setCookie('user_id', id, 60);
            first_name && setCookie('first_name', first_name);
            last_name && setCookie('last_name', last_name);
            email && setCookie('email', email);
            auth_token && setCookie('token', auth_token);
          }
          success(message);
          this.props.handleSubmitSuccess();
        }
      )
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    let adminAccessData = [];
    this.props.rolesdata.forEach((i) => {
      if (i.label !== 'Super Admin') {
        adminAccessData.push({ label: i.label, value: i.value });
      }
    });

    if (this.props.userData) {
      const {
        first_name,
        last_name,
        phone,
        status,
        email,
        id,
        role_group,
        pumps,
      } = this.props.userData;
      this.id = id;
      const { userData } = this.props;
      let selectedPump = [];
      let selectedRole = [];
      if (userData) {
        userData &&
          pumps &&
          pumps.forEach((val) => {
            selectedPump.push({ label: val.name, value: val.id });
          });
        if (role_group) {
          selectedRole = { label: role_group.name, value: role_group.id };
        }
      }
      let bool = selectedRole.label === 'Super Admin' ? true : false;

      this.setState({
        data: {
          ...this.state.data,
          first_name,
          last_name,
          phone,
          status: status && {
            label: capitaliseFirstLetter(status),
            value: status,
          },
          email,
          role_group: selectedRole,
          pump_ids: selectedPump,
        },
        superAdmin: bool,
        adminAccessData,
      });
    }
  };

  render() {
    const { onCancel, rolesdata, pumpData } = this.props;
    const { handleChange, handleMultiSelect, handleSelect, id } = this;
    const {
      first_name,
      last_name,
      email,
      phone,
      pump_ids,
      status,
      role_group,
    } = this.state.data;
    const { admin, adminAccessData, superAdmin } = this.state;
    return (
      <React.Fragment>
        <Layout>
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">{`${!id ? 'Create User' : 'Update User'
                    }`}</h4>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <AvForm>
                      <AvField
                        name="first_name"
                        label="First Name *"
                        placeholder={!id ? 'First Name' : null}
                        value={first_name}
                        onChange={handleChange}
                        type="text"
                        errorMessage="Enter First Name"
                        validate={{ required: { value: true } }}
                      />
                      <AvField
                        name="last_name"
                        label="Last Name"
                        placeholder={!id ? 'Last Name' : null}
                        value={last_name}
                        onChange={handleChange}
                        type="text"
                      />
                      <AvField
                        name="email"
                        label="E-Mail *"
                        placeholder={!id ? 'Email' : null}
                        value={email}
                        onChange={handleChange}
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                      />

                      <AvField
                        name="phone"
                        label="Phone *"
                        placeholder={!id ? 'Phone' : null}
                        value={phone}
                        type="phone"
                        onChange={handleChange}
                        errorMessage="Invalid Phone"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: '^[0-9]+$',
                            errorMessage: 'Only Numbers',
                          },
                          minLength: {
                            value: 10,
                            errorMessage:
                              'Your name must be between 10 numbers',
                          },
                          maxLength: {
                            value: 10,
                            errorMessage:
                              'Your name must be between 10 numbers',
                          },
                        }}
                      />
                      <Form>
                        <FormGroup>
                          <Label>Status</Label>
                          <Select
                            value={status}
                            onChange={(e) => {
                              handleSelect(e, 'status');
                            }}
                            options={statusSelect}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Role Group *</Label>
                          <Select
                            required
                            value={role_group}
                            onChange={(e) => {
                              handleSelect(e, 'role_group');
                            }}
                            options={admin ? adminAccessData : rolesdata}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Pumps</Label>
                          <Select
                            maxMenuHeight={160}
                            isDisabled={superAdmin ? 'true' : false}
                            value={superAdmin ? pumpData : pump_ids}
                            isMulti={true}
                            onChange={(e) => {
                              handleMultiSelect(e);
                            }}
                            options={pumpData}
                          />
                        </FormGroup>
                      </Form>
                      <FormGroup>
                        <Button
                          type="submit"
                          color="primary"
                          className="mr-1"
                          onClick={
                            !id
                              ? this.onSubmit
                              : () => {
                                this.onSubmit(id);
                              }
                          }
                        >
                          Submit
                        </Button>{' '}
                        <Button
                          type="reset"
                          color="secondary"
                          onClick={onCancel}
                        >
                          Cancel
                        </Button>
                      </FormGroup>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default UserForm;
