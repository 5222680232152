import { USER_PUMP } from './actionTypes';

const initialState = {
  error: null,
  loading: false,
  data: [],
};

const Pump = (state = initialState, action) => {
  switch (action.type) {
    case USER_PUMP:
      state = {
        ...state,
        loading: true,
      };
      break;

    // case PUMP_SHOW:
    //   state = {
    //     ...state,
    //     loading: true,
    //   };
    //   break;

    case 'USER_PUMP_SUCESS':
      state = {
        ...state,
        data: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Pump;
