import makeRequest from '.';
import moment from 'moment';

function generateParams(params = {}) {
  const timeFormat = (to_time) => {
    let t = moment(to_time);
    const formated_time = 0 - (t?.minute() % 30);
    const Time = moment(t).add(formated_time, 'minutes');
    return moment.utc(Time).format();
  };
  return `from_time=${timeFormat(params.fromTime)}&to_time=${timeFormat(
    params.toTime
  )}`;
}

export function fetchUserPump(requestParams) {
  return makeRequest({
    uri: `/api/v1/pumps/list?query=${requestParams || ''}`,
  });
}
export function fetchPumps() {
  return makeRequest({
    uri: `/api/v1/pumps`,
  });
}

export function getTileDetail(id) {
  return makeRequest({
    uri: `/api/v1/pumps/${id}/tiles`,
  });
}

export function getPumpData(id) {
  return makeRequest({
    uri: `/api/v1/pumps/${id}`,
  });
}

export function updatePump(id, requestBody) {
  return makeRequest({
    uri: `/api/v1/pumps/${id}`,
    method: 'PUT',
    body: JSON.stringify(requestBody),
  });
}

export function updateStatus(id, requestBody) {
  return makeRequest({
    uri: `/api/v1/pumps/${id}/state`,
    method: 'PUT',
    body: JSON.stringify(requestBody),
  });
}

export function createPump(id, requestBody) {
  return makeRequest({
    uri: '/api/v1/pumps',
    method: 'POST',
    body: JSON.stringify(requestBody),
  });
}

export function fetchSummary(pump_id, requestParams) {
  return makeRequest({
    uri: `/api/v1/pumps/${pump_id}/summary?${generateParams(requestParams)}`,
  });
}

export function fetchPumpLogs(pump_id, requestParams) {
  return makeRequest({
    uri: `/api/v1/pumps/${pump_id}/logs?${generateParams(requestParams)}`,
  });
}

export function liveData(pump_id, duration) {
  return makeRequest({
    uri: `/api/v1/pumps/${pump_id}/live?duration=${duration}`,
  });
}
