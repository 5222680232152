export const column = (e) => {
  return [
    {
      label: `${e === 'live' ? `Time (HH:MM:SS)` : `Time`}`,
      field: 'formated_time',
      width: 150,
    },
    {
      label: 'Frequency (Hz)',
      field: 'frequency',
      width: 150,
    },
    {
      label: 'Voltage (V)',
      field: 'voltage',
      width: 150,
    },
    {
      label: 'Current (A)',
      field: 'current',
      width: 150,
    },
    {
      label: 'Input Power (kW)',
      field: 'power',
      width: 150,
      align: 'center',
    },
    {
      label: 'Pump Discharge (Lps)',
      field: 'discharge',
      width: 150,
    },

    {
      label: 'Pump Head (m)',
      field: 'head',
      width: 150,
    },
    {
      label: 'Output Power (kW)',
      field: 'output',
      width: 150,
    },
    {
      label: 'System Efficiency (%)',
      field: 'efficiency',
      width: 200,
    },
  ];
};
