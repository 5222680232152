import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import UserTile from '../../components/UserTile';
import { fetchUser, fetchRoleGroup } from '../../api/users';
import { fetchPumps } from '../../api/pumps';
import Form from '../Forms/user/Form';
import 'chartist/dist/scss/chartist.scss';
import { handleResponse } from '../../components/handleResponse';
import Layout from 'components/VerticalLayout';
import { debounce } from 'helpers';

class Users extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      formOpen: false,
      userData: [],
      pumpData: [],
      rolesdata: [],
    };
  }

  initialRequestParams = () => {
    return {
      search: '',
    };
  };

  fetchUserData = () => {
    fetchUser(this.requestParams)
      .then(({ data }) => {
        this.setState({
          data: data,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };
  fetchPumpData = () => {
    fetchPumps()
      .then(({ data }) => {
        this.setState({
          pumpData: data && data.map((pump) => ({
            label: pump.name,
            value: pump.id,
          })),
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchRoleData = () => {
    fetchRoleGroup()
      .then(({ data }) => {
        this.setState({
          rolesdata: data.map((role) => ({
            label: role.name,
            value: role.id,
          })),
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount() {
    this.fetchUserData();
    this.fetchRoleData();
    this.fetchPumpData();
  }

  userAdd = (data) => {
    this.setState({
      formOpen: true,
      userData: data,
    });
  };

  onCancel = () => {
    this.setState({ formOpen: false });
  };

  handleSubmitSuccess = () => {
    this.fetchUserData();
    this.fetchPumpData();
    this.onCancel();
  };

  debounceSearch = debounce(() => {
    this.fetchUserData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.setState(this.state);
    this.setState(this.state);
    this.debounceSearch();
  };
  render() {
    let usr_roles = JSON.parse(window.localStorage.getItem('user_roles'));
    let add = usr_roles['api/v1/users'].includes('create');
    let update = usr_roles['api/v1/users'].includes('update');
    const { data, formOpen, userData, rolesdata, pumpData } = this.state;
    const { userAdd, onCancel, handleSubmitSuccess, onChange } = this;
    const updateProps = {
      data,
      onCancel,
      formOpen,
      userAdd,
      userData,
      pumpData,
      rolesdata,
      handleSubmitSuccess,
    };
    if (update && formOpen) {
      return <Form {...updateProps} />;
    }

    const TopBarProps = {
      dateFilter: false,
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };
    return (
      <React.Fragment>
        <Layout {...TopBarProps}>
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Users</h4>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item active">Users List</li>
                  </ol>
                </div>
              </Col>

              {add && (
                <Col sm="6">
                  <div className="float-right d-none d-md-block">
                    <Button
                      type="submit"
                      color="primary"
                      className="mr-1"
                      onClick={userAdd}
                    >
                      <i
                        className=" mdi mdi-hospital"
                        style={{ marginRight: '10px' }}
                      ></i>
                      Add User
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
            <Row style={{ marginLeft: '1%' }}>
              <UserTile {...updateProps} />
            </Row>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default Users;
