import { apiHost } from '../config';
import { success, handleResponse } from 'components/handleResponse';
import { jsonHeaders, getCookie, setCookie } from '../helpers';

export function signIn(user_name, password) {
  return fetch(`${apiHost}/login`, {
    method: 'POST',
    headers: jsonHeaders,
    body: JSON.stringify({ user_name, password }),
  }).then(async (response) => {
    if (response.ok) {
      success('Login Successfully.!!!');
      return response.json();
    }
    throw [response.status, await response.json()];
  });
}

export function forgotPassword(user_name) {
  return fetch(`${apiHost}/forgot-password`, {
    method: 'PUT',
    headers: jsonHeaders,
    body: JSON.stringify({
      user_name,
    }),
  }).then(async (response) => {
    if (response.ok) {
      success('OTP Sent Successfully.!!!');
      return response.json();
    } else {
      handleResponse('Incorrect username or password');
    }
    throw [response.status, await response.json()];
  });
}

export function updatePassword(requestBody) {
  return fetch(`${apiHost}/reset-password`, {
    method: 'PUT',
    headers: jsonHeaders,
    body: JSON.stringify({ ...requestBody }),
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    throw [response.status, await response.json()];
  });
}

var lastLoginCalled = false;
export async function lastLogin() {
  if (lastLoginCalled) {
    return;
  }
  lastLoginCalled = true;
  const reqHeaders = {
    ...jsonHeaders,
    Authorization: getCookie('token'),
  };
  return fetch(apiHost + '/last_login', {
    method: 'PUT',
    headers: reqHeaders,
  })
    .then(() => {
      lastLoginCalled = false;
      setCookie('active', '1', 0, 2);
    })
    .catch((error) => {
      lastLoginCalled = false;
      handleResponse(error);
    });
}
