export const apiHost = "https://api-ssp.sitarc.com";
export const AppVersion = "1.13.8";

export const enableGraylog = "";
export const graylogFacility = "";
export const graylogUrl = "";
export const graylogHost = "";

export const honeyBadger = "";

export const statusSelect = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const pumpCategory = [
  { label: "Danfoss", value: "deccan" },
  { label: "Indus", value: "indus" },
]

export const graphRange = [
  { label: "5 Mins", value: "5" },
  { label: "15 Mins", value: "15" },
  { label: "30 Mins", value: "30" },
];
