import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import { column } from 'data/logs';
import { fetchPumpLogs, getPumpData } from 'api/pumps';
import { handleResponse } from 'components/handleResponse';
import Layout from 'components/VerticalLayout';
import moment from 'moment';
import { userPump } from 'store/pump/actions';

class Logs extends Component {
  constructor(props) {
    super(props);
    this.time = null;
    this.state = {
      dataValue: [],
      pumpData: {},
      device: {
        label: localStorage.getItem('pump_name'),
        value: localStorage.getItem('pump_id'),
        device_id: localStorage.getItem('device_id'),
      },
    };
    this.requestParams = this.initialRequestParams();
  }

  initialRequestParams = () => {
    return {
      fromTime: moment.utc(moment().subtract(12, 'hours')).format(),
      toTime: moment.utc(moment()).format(),
    };
  };

  onDateChange = (from_time, to_time) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.handleFetchAllData();
  };

  handleSelect = (device) => {
    const { value: pumpId, label, device_id } = device;
    localStorage.setItem('pump_id', pumpId);
    localStorage.setItem('pump_name', label);
    localStorage.setItem('device_id', device_id);
    this.props.history.push(
      device_id ? `/pump/${device_id}/logs` : `/dashboard`
    );
    this.setState({ device }, () => this.handleFetchAllData(pumpId));
  };

  fetchPumpDetailData = (id) => {
    getPumpData(id)
      .then(({ data: pumpData }) => {
        this.setState({ pumpData });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchErrorLogs = (pumpId) => {
    fetchPumpLogs(pumpId, this.requestParams)
      .then(({ data }) => {
        let obj = [];
        data &&
          data.logs &&
          data.logs.forEach((datum) =>
            obj.push({
              error_message: datum?.message,
              created_at: moment(datum?.created_at).format(
                'Do, MMM, YYYY hh:mm a'
              ),
              pump_name: data?.name,
              device_id: data?.device_id,
            })
          );
        this.setState({ dataValue: obj });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleFetchAllData = () => {
    const pumpId = localStorage.getItem('pump_id');
    if (!pumpId) {
      this.props.history.push(`/dashboard`);
    }
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.timer = setInterval(() => {
      this.fetchPumpDetailData(pumpId);
    }, 15000);

    //Default
    this.fetchPumpDetailData(pumpId);
    this.fetchErrorLogs(pumpId);
  };

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  componentDidMount = () => {
    const { userPumpList } = this.props;
    userPumpList.length === 0 && this.props.userPump();
    this.handleFetchAllData();
  };

  render() {
    const { onDateChange, handleSelect } = this;
    const { dataValue, device, pumpData } = this.state;

    let data = {
      columns: column,
      rows: dataValue,
    };
    const appProps = {
      dateFilter: true,
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: false,
      onDateChange,
      select: true,
      handleSelect,
      device,
      stateDetails: true,
      state: pumpData?.state,
    };
    return (
      <React.Fragment>
        <Layout {...appProps}>
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Logs</h4>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">Pump Log Details</li>
                  </ol>
                </div>
              </Col>
            </Row>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <p className="card-title-desc"></p>
                    <MDBDataTable
                      style={{ textAlign: 'center' }}
                      responsive
                      striped
                      bordered
                      noBottomColumns
                      entries={50}
                      data={{ ...data }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}
const mapDispatchtoProps = (state) => {
  const { error, loading, data } = state.Pump;
  return { error, loading, userPumpList: data };
};

export default withRouter(connect(mapDispatchtoProps, { userPump })(Logs));
