import React, { Component } from 'react';

class Logout extends Component {
  componentDidMount = () => {};

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default Logout;
